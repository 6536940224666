import React from 'react';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';

const NotFoundPage = (): React.ReactElement => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>Oh no!</h1>
    <p>There&apos;s nothing here!</p>
  </Layout>
);

export default NotFoundPage;
